import Card from 'react-bootstrap/Card'
import { Button, CardDeck, Form, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useHistory } from 'react-router'
import MainLayout from '../components/MainLayout'
import { BlobStreamer } from '../utils/BlobStreamer'
import { createSHA256 } from 'hash-wasm'
import Api from '../backend/api'

interface ByFileHashForm {
    hashType : "source" | "certificate"
}

function ByFileHashForm(props: ByFileHashForm) {
    const { t } = useTranslation('common')
    const [fileHash, setFileHash] = useState<any>()
    const [working, setWorking] = useState(false)
    const [progress, setProgress] = useState('0%')
    const [err, setErr] = useState<any>()
    const history = useHistory();

    const onFileChange = async (e: any) => {
        setFileHash(null)
        if (e.target.files.length > 0) {
            setWorking(true)
            try {
                const file = e.target.files[0]
                if (file.size > 0) {
                    const stream = new BlobStreamer(file)
                    const chunks = Math.ceil(file.size / stream.defaultChunkSize)
                    let chunk = 0
                    const digest = await createSHA256()
                    digest.init()
                    setProgress('0%')
                    while (!stream.isEndOfBlob()) {
                        digest.update(new Uint8Array(await stream.readBlockAsArrayBuffer()))
                        chunk++;
                        setProgress((100.0 * chunk / chunks).toFixed(2) + "%")
                    }
                    const hash = digest.digest('binary') as any
                    const hash_b64 = btoa(String.fromCharCode.apply(null, hash))
                    setFileHash(hash_b64)
                }
                else {
                    setErr(t('Invalid file'))
                }
            }
            catch (error) {
                setErr(t('Error processing the file'))
            }
            setWorking(false)
        }
    };
    
    return (
        <Card>
            <Card.Body>
                <Form>
                    <Form.Group>
                        {/* <Form.Label>{props.hashType === 'source' ? t('Select a local file to verify') : t('By Certificate File')}</Form.Label> */}
                        <Form.Control type="file" onChange={onFileChange} />
                        {!fileHash &&
                        <Form.Text className="text-muted">
                            {props.hashType === 'source'
                                ? t('The file will be processed locally on your device only. Full confidentiality')
                                : t('Select the certificate file to verify it.')
                            }
                        </Form.Text>
                        }
                        {working &&
                            <Form.Text>
                                <Spinner size="sm" animation="border" variant="primary" />{' '}{t('Calculating hash...')}{' '}{progress}
                            </Form.Text>
                        }
                        {err &&
                            <Form.Text className="text-danger">
                                {err}
                            </Form.Text>
                        }
                    </Form.Group>
                    {fileHash && 
                        <Form.Group>
                            <Form.Label>{t('Hash')}:</Form.Label>
                            <Form.Control type="text" value={fileHash} readOnly />
                            <Form.Text className="text-muted">
                                {t('SHA256 and Base64 encoded')}
                            </Form.Text>
                        </Form.Group>
                    }               
                </Form>
            </Card.Body>
            {fileHash &&
                <Card.Footer>
                    <Button variant="primary" type="submit" disabled={!fileHash} onClick={async () => {
                        setErr(null)
                        try {
                            var cert = await Api.getCertificateData({fileHash})
                            if (cert.error) {
                                cert = await Api.getCertificateData({certificateHash: fileHash})
                                if (cert.error) {
                                    setErr(t('Selected file is NOT CERTIFIED'))
                                }
                                else {
                                    history.push(`/certificate/${cert.id}`)
                                }
                            }
                            else {
                                history.push(`/certificate/${cert.id}`)
                            }
                        }
                        catch (error) {
                            console.log("Error", error)
                        }
                    }}>
                    {t('Verify')}
                    </Button>
                </Card.Footer>
            }
        </Card>
    )
}

// function ByCertNumberForm(props: {}) {
//     const { t } = useTranslation('common')
//     const [certId, setCertId] = useState(0)
//     const history = useHistory();
//     return (
//         <Card>
//             <Card.Body>
//                 <Form>
//                     <Form.Group controlId="byNumber">
//                         <Form.Label>{t('By Certificate Number')}</Form.Label>
//                         <Form.Control type="text" placeholder={t('Certificate Number')} onChange={(v) => {
//                             setCertId(parseInt(v.target.value))
//                         }} />
//                         <Form.Text className="text-muted">
//                             {t('Enter the unique number associated with the certificate.')}
//                         </Form.Text>
//                     </Form.Group>
//                 </Form>                
//             </Card.Body>
//             <Card.Footer>
//                 <Button variant="primary" type="submit" disabled={isNaN(certId) || certId <= 0} onClick={() => {
//                     history.push(`/certificate/${certId}`)
//                 }}>
//                 {t('verify')}
//                 </Button>
//             </Card.Footer>
//         </Card>
//     )
// }

export default function VerifyCertPage(props:{ key: any }) {
    const { t } = useTranslation('common')
    return (
        <MainLayout key={props.key}>
            <div className="verify-form-wrapper-ver">
                <div>
                    <h3 className="verify-form-wrapper-title-ver">{t('Verify')}</h3>
                    <CardDeck>
                        {/* <ByCertNumberForm /> */}
                        <ByFileHashForm hashType="source" />
                        {/* <ByFileHashForm hashType="certificate" /> */}
                    </CardDeck>
                </div>
            </div>
        </MainLayout>
    )
}
