import React, { Fragment } from 'react'
import TopBar from './TopBar'
import MainNavigationBar from './MainNavigationBar'
import { Container, Row, Col } from 'react-bootstrap'

type MainLayoutProps = React.PropsWithChildren<{
    getit?: boolean
    key?: any
}>;

export default function MainLayout(props: MainLayoutProps) {
    return <Fragment key={props.key}>
        <TopBar />
        <MainNavigationBar getit={props.getit} />
        <Container>
            <Row>
                <Col>
                    {props.children}
                </Col>
            </Row>
        </Container> 
    </Fragment>
}

