import { gql } from "@apollo/client";

export const QUERY_CERTIFICATES = gql`
query QUERY_CERTIFICATES($endOffset: String) {
    certificates: ch_certificate_connection(order_by: {issued_: desc, id_: desc}, first: 5, after: $endOffset) {
        edges {
            node {
                id: id_
                fileName: file_name_
                fileHash: hash_id_
                certificateHash: certificate_hash_
                issued: issued_
                description: note_
                revocationCause : revocation_cause_
                revoked : revoked_
                token: token_id_
                notarization: ch_notarization {
                    start : start_
                    timestamp : timestamp_
                    state : state_
                    privateNetwork : private_network_
                    privateChainId : private_chain_id_
                    privateTx : private_tx_
                    privateBlockHash : private_block_hash_
                    privateBlockNum : private_block_num_
                    publicNetwork : public_network_
                    publicChainId : public_chain_id_
                    publicTx : public_tx_
                    publicBlockHash : public_block_hash_
                    publicBlockNum : public_block_num_
                
                }
            }
            cursor
        }
        pageInfo {
            endCursor
            hasPreviousPage
            hasNextPage
        }        
    }
}
`;