// Copyright: Flavien Volken
// https://gist.github.com/Xample/c1b7664ba33e09335b94379e48a00c8e

export type bytes = number;

export class BlobStreamer {
  public readonly defaultChunkSize = 64 * 1024; // 64k (more is faster but makes chrome crash on large blobs?!)
  private offset: bytes = 0;

  constructor(private blob: Blob) {
    this.rewind();
  }

  public rewind(bytesLength: bytes = this.offset): void {
    this.offset -= bytesLength;
  }

  public isEndOfBlob(): boolean {
    return this.offset >= this.getBlobSize();
  }

  public readBlockAsArrayBuffer(length: bytes = this.defaultChunkSize): Promise<ArrayBuffer> {

    const fileReader: FileReader = new FileReader();
    const blob: Blob = this.blob.slice(this.offset, this.offset + length);

    return new Promise<ArrayBuffer>((resolve, reject) => {
      fileReader.onload = (event: Event) => {
        const data = this.getArrayBufferFromEvent(event);
        this.shiftOffset(blob.size);
        if (data) {
            resolve(data);
        }
      };

      fileReader.onerror = (event) => {
        reject(event.target?.error);
      };

      fileReader.readAsArrayBuffer(blob);
    });
  }

  protected shiftOffset(bytesRead: bytes): void {
    this.offset += bytesRead;
  }

  protected getArrayBufferFromEvent(event: Event): ArrayBuffer | null {
    const target: FileReader = (event.target) as FileReader;
    return target.result as ArrayBuffer;
  }

  private getBlobSize(): number {
    return this.blob.size;
  }
}