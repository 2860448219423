import { useSessionState } from '../utils/hooks'
import Settings from './settings'


export interface CertificateData {
    id: number,
    accountName : string
    accountKey: string
    fileName: string
    fileHash: string
    issued: string
    valid: boolean
    revoked: string
    revocationCause: string
    description: string
    certUrl: string
    error: boolean

    notarization: {
        start: string
        timestamp: string
        state: number
        privateNetwork: string
        privateChainId: string
        privateTx: string
        privateBlockHash: string
        privateBlockNum: string
        publicNetwork: string
        publicChainId: string
        publicTx: string
        publicBlockHash: string
        publicBlockNum: string
    }

}

export interface AuthInfo {
    state: "signed" | "waiting"
    accountId: number
    accountName: string
    code: string
    jwt: string
}

export interface TokenInfo {
    status: string,
    message: string
    id: string,
    type: number,
    account: {
        id: number,
        name: string,
        key: string
    }
}

export function useAuth() {
    return useSessionState<AuthInfo|null>('auth')
}

export interface CreateCertificateRequest {
    note? : string,
    filename: string,
    hash: string,
    transfer? : string,
    sendTo? : string
}

export interface CreateCertificateResponse {
    status: string,
    message: string,
    id: number
}

const Api = {

    async getCertificateData({certificateId, fileHash, certificateHash} : {certificateId? : number, fileHash? : string, certificateHash? : string}) {
        const url = `${Settings.apiUrl}/certificates/validation-request`
        console.log(url)
        const response = await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "post",
            body: JSON.stringify({
                certificateId,
                fileHash,
                certificateHash
            }),
            mode: "cors"
        })
        if (response.status === 200) {
            const json = await response.json()
            const data = json as CertificateData

            const historyJson = localStorage.getItem('my-certs')
            if (historyJson) {
                const hist = JSON.parse(historyJson) as CertificateData[]
                const nhist = [data]
                hist.forEach((d) => {
                    if (d.id !== data.id) {
                        nhist.push(d)
                    }
                })
                localStorage.setItem('my-certs', JSON.stringify(nhist))
            }

            return data
        }
        else {
            console.log(`${response.statusText}\n${await response.text()}`)
            return {
                error: true
            } as CertificateData
        }
    },

    getCertPdfUrl(id : number) {
        return `${Settings.apiUrl}/certificates/${id}.pdf`
    },

    async createAsset(token: string, req: CreateCertificateRequest): Promise<CreateCertificateResponse> {
        const url = `${Settings.apiUrl}/certificates/tokens/${token}`
        console.log(url)
        const response = await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "post",
            body: JSON.stringify(req),
            mode: "cors"
        })
        if (response.status === 200) {
            const json = await response.json()
            return json as CreateCertificateResponse
        }
        else {
            console.log(`${response.statusText}\n${await response.text()}`)
            return {
                status: "error"
            } as CreateCertificateResponse
        }
    },

    async validateTokens(tokens: Array<string>): Promise<Array<string>> {
        const url = `${Settings.apiUrl}/tokens/validate`
        console.log(url)
        const response = await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "post",
            body: JSON.stringify({tokens: tokens}),
            mode: "cors"
        })
        if (response.status === 200) {
            const json = await response.json()
            return json.tokens as Array<string>
        }
        else {
            return []
        }
    },

    async getToken(tokenId: string): Promise<TokenInfo> {
        const url = `${Settings.apiUrl}/tokens/${tokenId}`
        console.log(url)
        const response = await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "get",
            mode: "cors"
        })
        if (response.status === 200) {
            const json = await response.json()
            return json as TokenInfo
        }
        else {
            throw {status: "error", message: 'Invalid Token'} // eslint-disable-line no-throw-literal
        }
    }

}

export default Api;
