import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import MainLayout from "../components/MainLayout"

import youtube from "../assets/images/youtube.svg"
import google from "../assets/images/google.png"
import apple from "../assets/images/apple.png"
import arrow from "../assets/images/arrow1.svg"

export default function GetItPage(props: {}) {
    const { t } = useTranslation('common')
    return (
        <MainLayout getit={false}>        
            <div className="centered-wrapper">
                <Container fluid style={{marginTop: "5rem"}}>
                    <Row>
                        <Col>
                            <a href="#"><img src={youtube} style={{maxHeight: "4rem"}} /></a><br />
                            <h2 className="white-big-text" >How Does It Work</h2>
                        </Col>
                        <Col style={{textAlign: "right",}}  className="d-none d-md-block">
                            <img src={arrow} style={{maxHeight: "5rem"}}/>
                        </Col>
                        <Col>
                            <a href="https://play.google.com/store/apps/details?id=org.fileproof.certihash"><img src={google} className="appstore-logo" /></a>
                            <a href="#"><img src={apple} className="appstore-logo" /></a>
                        </Col>
                    </Row>
                </Container>
            </div>
        </MainLayout>
    )
}

