import Card from 'react-bootstrap/Card'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Api, { CertificateData } from '../backend/api'
import { asyncEffect } from '../utils/hooks'
import QRCode from 'qrcode.react'
import { Link } from 'react-router-dom'
import MainLayout from '../components/MainLayout'

interface CertificatePageParams {
    id? : string
    hash? : string
}

interface CertData {
    accountName: string
    accountKey: string
    fileName: string
    fileHash: string
    issued: string
    valid: boolean
    revoked: string
    revocationCause: string
    notarization: {
        state: number
    }
}


function RevokationData(props: { data: CertData }) {
    const { t } = useTranslation('common')
    const reasons = {
        "1": t('Owner request'),
        "2": t('Admin request'),
        "3": t('Ownership transfer')
    } as any
    return <>
        <Card bg="danger" text="light">
            <Card.Body>
                <Form.Group controlId="revoked" as={Row}>
                    <Form.Label column sm="4">{t('Revoked')}:</Form.Label>
                    <Col>
                        <Form.Control type="text" value={props.data.revoked} readOnly />
                    </Col>
                </Form.Group>
                <Form.Group controlId="revoked-reason" as={Row}>
                    <Form.Label column sm="4">{t('Revocation reason')}:</Form.Label>
                    <Col>
                        <Form.Control type="text" value={reasons[props.data.revocationCause]} readOnly />
                    </Col>
                </Form.Group>
            </Card.Body>
        </Card>
    </>
}


function certificateForm(data : CertificateData, t : any) {
    const status = t('notarization_state/' + data?.notarization?.state)
    return  <Card>
    <Card.Body>
        <Form>
            <Row>
                <Col>
                    <Form.Group controlId="id">
                        <Form.Label>{t('Certificate Number')}:</Form.Label>
                        <Form.Control type="text" readOnly value={data.id || ''} />
                    </Form.Group>
                    <Form.Group controlId="account">
                        <Form.Label>{t('Owner')}:</Form.Label>
                        <Form.Control type="text" readOnly value={data?.accountName || ''} />
                    </Form.Group>
                    <Form.Group controlId="account">
                        <Form.Label>{t('Owner key fingerprint')}:</Form.Label>
                        <Form.Control type="text" readOnly value={data?.accountKey || ''} />
                    </Form.Group>
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <div className="qr-container">
                                <QRCode value={window.location.href} />
                            </div>
                            <Card.Text className="text-muted text-center">
                                {window.location.href}
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="file">
                        <Form.Label>{t('File Name')}:</Form.Label>
                        <Form.Control type="text" readOnly value={data?.fileName || ''} />
                    </Form.Group>
                    <Form.Group controlId="hash">
                        <Form.Label>{t('File Hash')}:</Form.Label>
                        <Form.Control type="text" readOnly value={data?.fileHash || ''} />
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>{t('Description')}:</Form.Label>
                        <Form.Control as="textarea" value={data?.description || ''} rows={3} readOnly />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="status">
                        <Form.Label>{t('Status')}:</Form.Label>
                        <Form.Control className={data?.valid ? '' : 'text-danger'} type="text" value={status} readOnly />
                    </Form.Group>
                    <Form.Group controlId="timestamp">
                        <Form.Label>{t('Timestamp')}:</Form.Label>
                        <Form.Control type="text" readOnly value={data?.issued || ''} />
                    </Form.Group>
                    <Form.Group controlId="blockchain">
                        <Form.Label>{t('Private BC Transaction')}:</Form.Label>
                        <Form.Control type="text" value={data?.notarization?.privateTx || ''} readOnly />
                    </Form.Group>
                    <Form.Group controlId="pubblockchain">
                        <Form.Label>{t('Public BC Transaction')}:</Form.Label>
                        <Form.Control type="text" value={data?.notarization?.publicTx || ''} readOnly />
                    </Form.Group>
                </Col>
            </Row>
            {data?.revoked && <RevokationData data={data} />}
        </Form>
    </Card.Body>
    <Card.Footer>
        <a href={data === null ? '#no-pdf' : Api.getCertPdfUrl(data.id)}>
            <FontAwesomeIcon icon="file-pdf" />{'  '}
            {t('Download')}
        </a>
        <Link to="/" className="float-right">
            {t('Close')}
        </Link>        
    </Card.Footer>
</Card>

}


function _notFound(id : any, t: any) {
    return <Card>
        <Card.Header>
            {t('Checking')}
        </Card.Header>
        <Card.Body>
            <Card.Text>
                {t('Certificate not found')}: {id}
            </Card.Text>
            <Card.Text>
                <Link to="/">
                    {t('Close')}
                </Link>
            </Card.Text>
        </Card.Body>
    </Card>
}


function _loading(id : any, t: any) {
    return <Card>
        <Card.Header>
            {t('Loading')}
        </Card.Header>
        <Card.Body>
            <Spinner animation="border" size="sm" variant="primary" />{' '}
            {t('Searching for certificate')}: {id}
            <Card.Text>
                <Link to="/">
                    {t('Close')}
                </Link>
            </Card.Text>
        </Card.Body>
    </Card>
}


export default function CertificatePage(props: {}) {
    const { t } = useTranslation('common')
    const { id } = useParams<CertificatePageParams>()
    const [data, setData] = useState<CertificateData>()

    useEffect(asyncEffect(async () => { // eslint-disable-line react-hooks/exhaustive-deps
        try {
            setData(await Api.getCertificateData({certificateId: parseInt(id || '0')}))
        }
        catch (error) {
            console.log('Error', error)
            setData({error: true} as CertificateData)
        }
    }), [id])

    return (
        <MainLayout>        
            <div className="centered-wrapper">
                <div style={{ maxWidth: '100%', width: '800px' }}>
                    <h3 className="centered-wrapper-title" >{t('Certificate of registration')}</h3>
                    {data 
                        ? data.error 
                            ? _notFound(id, t) 
                            : certificateForm(data, t) 
                        : _loading(id, t)}
                </div>
            </div>
        </MainLayout>
    )
}

