import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Branding from '../components/brand';

import { useTranslation } from 'react-i18next'
import SessionButton from './SessionButton'

export default function TopBar(props : {}) {
    const { t } = useTranslation('common')
    return (<>
            <div className="brand-topbar">
                <a href={"tel:" + Branding.phoneNumber}>
                    <FontAwesomeIcon icon="phone" />{' '}
                    {Branding.phoneText}
                </a>
                <a href={"mailto:" + Branding.email}>
                    <FontAwesomeIcon icon="envelope" />{' '}
                    {Branding.email}
                </a>
                <a href="/" className="d-none d-md-block">
                    <FontAwesomeIcon icon="clock" />{' '}
                    {t('working-hours')}
                </a>
                <SessionButton />                        
            </div>
    </>)
}

