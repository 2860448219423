import { ApolloProvider } from '@apollo/client';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { BackendAuthContext } from '../App';
import { createApolloClient } from '../backend/apollo';

type BackendProps = React.PropsWithChildren<{}>;

const Backend = (props: BackendProps) => {
    const {auth} = useContext(BackendAuthContext)
    const history = useHistory()
    const { t } = useTranslation('common')
    if (!auth) {
        history.replace('/login')
        return <span className="text-danger">
            {t('Authentication required')}
        </span>
    }
    else {
        const client = createApolloClient(auth.jwt);
        return (
            <ApolloProvider client={client}>
                {props.children}
            </ApolloProvider>
        )
    }
}

export default Backend;
