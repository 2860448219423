import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { BackendAuthContext } from "../App";

export default function SessionButton(props: {}) {
    const {t} = useTranslation('common')
    const {auth, setAuth} = useContext(BackendAuthContext)
    const history = useHistory()
    if (auth) {
        return (
            <>
                <Link to="/dashboard">{auth.accountName}</Link>
                <a style={{marginLeft: "20px"}} href="#logout" onClick={(e) => {
                    e.preventDefault();
                    setAuth(null)
                    history.replace('/')
                }}>
                    {t('logout')}
                </a>
            </>
        )
    }
    else {
        return <>           
        </>
    }
}

