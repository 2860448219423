import { useState } from "react";
import { Button, Card, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MainLayout from "../components/MainLayout";
import { createSHA256 } from 'hash-wasm';
import { BlobStreamer } from "../utils/BlobStreamer";
import { useLocalState, useSessionState } from "../utils/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import Api from "../backend/api";

interface CertFormData {
    token: string,
    sendTo?: string,
    description?: string,
    transfer?: string,
    file: Blob,
    hash: string
};

type CertFormEvent = (data: CertFormData) => void;
type TokenSelectedEvent = (token: string) => void;
type CloseEvent = () => void;

function TokenBrowser(props: { show: boolean, onSelected: TokenSelectedEvent, onClose: CloseEvent, tokens: Map<string, string> }) {
    const { t } = useTranslation("common")
    return (
        <Modal show={props.show} onHide={props.onClose} size="sm" centered>
            <Modal.Header closeButton>{t('Select a token from your list')}</Modal.Header>
            <div className="token-list">
                {props.tokens && Array.from(props.tokens.keys()).map((token) => 
                    <div className="token-list-item" key={token}>
                        <div className="token-list-account" onClick={() => props.onSelected(token)}>
                            <FontAwesomeIcon icon="key" />{' '}
                            {props.tokens.get(token)}
                        </div>
                        <div className="token-list-token" onClick={() => props.onSelected(token)}>{token}</div>
                    </div>)
                }
            </div>
        </Modal>
    )
}

function TokenSearchButton(props: { onSelected: TokenSelectedEvent }) {
    const [tokens, setTokens] = useLocalState<Map<string, string>>("my-tokens", new Map())
    const [working, setWorking] = useState(false)
    const [showTokens, setShowTokens] = useState(false)
    const refreshAndShow = async () => {
        setWorking(true)
        if (tokens?.size) {
            const remote = await Api.validateTokens(Array.from(tokens?.keys()))
            const verified = new Map<string, string>()
            remote.forEach((t) => verified.set(t, tokens.get(t)!))
            setTokens(verified)
        }
        setWorking(false)
        setShowTokens(true)
    };
    const selected = (v:string) => {
        props.onSelected(v)
        setShowTokens(false)
    };
    return (
        <>
            <TokenBrowser onClose={() => setShowTokens(false)} show={showTokens} onSelected={selected} tokens={tokens} />
            {working
                ? <Spinner size="sm" animation="border" />
                : <FontAwesomeIcon icon="search" onClick={() => refreshAndShow()} />
            }
        </>
    )
}


function CertForm(props: { onSubmit: CertFormEvent }) {
    const { t } = useTranslation('common')
    const [token, setToken] = useState('')
    const [sendTo, setSendTo] = useSessionState('LastSentTo', '')
    const [description, setDescription] = useState('')
    const [transfer, setTransfer] = useState('')
    const [file, setFile] = useState<any>()
    const [fileHash, setFileHash] = useState<any>()
    const [working, setWorking] = useState(false)
    const [progress, setProgress] = useState('0%')
    const [err, setErr] = useState<any>()


    const onFileChange = async (e: any) => {
        setFile(null)
        setFileHash(null)
        if (e.target.files.length > 0) {
            //const start = new Date()
            setWorking(true)
            try {
                const file = e.target.files[0]
                if (file.size > 0) {
                    setFile(file)
                    const stream = new BlobStreamer(file)
                    const chunks = Math.ceil(file.size / stream.defaultChunkSize)
                    let chunk = 0
                    const digest = await createSHA256()
                    digest.init()
                    setProgress('0%')
                    while (!stream.isEndOfBlob()) {
                        digest.update(new Uint8Array(await stream.readBlockAsArrayBuffer()))
                        chunk++;
                        setProgress((100.0 * chunk / chunks).toFixed(2) + "%")
                    }
                    const hash = digest.digest('binary') as any
                    const hash_b64 = btoa(String.fromCharCode.apply(null, hash))
                    // console.log("SIZE: " + (file.size/1000000) + "MB")
                    // console.log("TIME: " + ((new Date().getTime() - start.getTime())/1000.0) + "s")
                    // console.log("HASH: " +  hash_b64)

                    setFileHash(hash_b64)
                }
                else {
                    setErr(t('Invalid file'))
                }
            }
            catch (error) {
                setErr(t('Error processing the file'))
            }
            setWorking(false)
        }
    };

    const onSubmitForm = (e: any) => {
        e.preventDefault();
        props.onSubmit({ token, sendTo, description, transfer, file, hash: fileHash })
    };

    return (
        <>
            <Form onSubmit={onSubmitForm}>
                <Form.Group controlId="cert-file">
                    <Form.Label>{t('File')}</Form.Label>
                    <Form.Control type="file" onChange={onFileChange} />
                    {!fileHash &&
                        <Form.Text className="text-muted">
                            {t('Select the file to certify. The file will be processed locally on your device')}
                        </Form.Text>
                    }
                    {working &&
                        <Form.Text>
                            <Spinner size="sm" animation="border" variant="primary" />{' '}{t('Calculating hash...')}{' '}{progress}
                        </Form.Text>
                    }
                    {err &&
                        <Form.Text className="text-danger">
                            {err}
                        </Form.Text>
                    }
                </Form.Group>
                {fileHash && 
                    <Form.Group controlId="cert-hash">
                        <Form.Label>{t('Hash')}:</Form.Label>
                        <Form.Control type="text" value={fileHash} readOnly />
                        <Form.Text className="text-muted">
                            {t('SHA256 and Base64 encoded')}
                        </Form.Text>
                    </Form.Group>
                }               
                <Form.Group controlId="cert-token">
                    <Form.Label>{t('Token')}:</Form.Label>
                    <InputGroup className="mb-2">
                        <Form.Control type="text" value={token} onChange={(e) => setToken(e.target.value)} disabled={!fileHash} />
                        <InputGroup.Append>
                            <InputGroup.Text>
                                <TokenSearchButton onSelected={(v) => setToken(v)} />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    <Form.Text className="text-muted">
                        {t('Enter a valid certification Token')}
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="cert-sendto">
                    <Form.Label>{t('Send to')}:</Form.Label>
                    <Form.Control type="text" value={sendTo} onChange={(e) => setSendTo(e.target.value)} disabled={!fileHash} />
                    <Form.Text className="text-muted">
                        {t('Enter email address where to send the certificate')}
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="cert-descr">
                    <Form.Label>{t('Description')}:</Form.Label>
                    <Form.Control as="textarea" rows={2} value={description} onChange={(e) => setDescription(e.target.value)} disabled={!fileHash} />
                    <Form.Text className="text-muted">
                        {t('Add an optional description to include in your certificate')}
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="cert-transfer-token">
                    <Form.Label>{t('Transfer token')}:</Form.Label>
                    <Form.Control type="text" value={transfer} onChange={(e) => setTransfer(e.target.value)} disabled={!fileHash} />
                    <Form.Text className="text-muted">
                        {t('If you are requesting a transfer of ownership, please enter your authorization token here.')}
                    </Form.Text>
                </Form.Group>
                <Button variant="primary" type="submit" disabled={!fileHash}>Send</Button>
            </Form>            
        </>
    )

}


export default function CreateAssetPage(props: {}) {
    const { t } = useTranslation('common')
    const [ working, setWorking ] = useState(false)
    const [ err, setErr ] = useState<any>(null)
    const [ tokens, setTokens ] = useLocalState('my-tokens', new Map<String, any>())
    const history = useHistory()
    const create = async (form: CertFormData) => {
        setWorking(true)
        setErr(null)
        try {
            const resp = await Api.createAsset(form.token, {
                filename: (form.file as any).name,
                hash: form.hash,
                note: form.description,
                sendTo: form.sendTo,
                transfer: form.transfer
            })
            if (resp.status && resp.status !== 'ok' ) {
                setErr(t('Invalid Token'))
            }
            else {
                tokens.delete(form.token)
                setTokens(new Map(tokens.entries()))
                history.replace(`/certificate/${resp.id}`)
            }
        }   
        catch (error) {
            setErr(t('Invalid Token'))
        }
        setWorking(false)
    };

    return <MainLayout>
        <div className="centered-wrapper">
            <div style={{ maxWidth: '100%', width: '800px' }}>
                <h3 className="centered-wrapper-title" >{t('Notarize a file')}</h3>
                <Card>
                    <Card.Body>
                        {working && 
                            <div>
                                <Spinner animation="border" />{' '}{t('Processing...')}
                            </div>
                        }
                        {!working && 
                            <>
                                <CertForm onSubmit={create} />
                                {err && <Card.Text className="text-danger">{t('Invalid Token')}</Card.Text>}
                            </>
                        }
                    </Card.Body>
                </Card>
            </div>
        </div>
    </MainLayout>
}