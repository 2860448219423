import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar, Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Api, { CertificateData, useAuth } from "../backend/api";
import MainLayout from "../components/MainLayout";
import { asyncEffect, useLocalState } from "../utils/hooks";


function ConfirmDeleteButton({onConfirm}:any) {
    const [expanded, setExpanded] = useState(false)
    const {t} = useTranslation('common')
    if (expanded) {
        return (
            <>
            <ButtonGroup size="sm" className="float-right">
                <Button onClick={onConfirm} variant="danger" title={t('Confirm delete')}>
                    <FontAwesomeIcon icon="trash" />
                </Button>
                <Button onClick={() => setExpanded(false)} variant="secondary" title={t('Cancel')}>
                    <FontAwesomeIcon icon="times" />
                </Button>
            </ButtonGroup>
            </>
        )
    }
    return (
        <Button onClick={() => setExpanded(true)} size="sm" className="float-right" title={t('Delete')}> 
            <FontAwesomeIcon icon="trash" />
        </Button>
    )
}



function LocalTokensTable() {

    const [tokens, setTokens] = useLocalState<Map<string, string>>("my-tokens", new Map())
    const [working, setWorking] = useState(false)
    const {t} = useTranslation('common')

    useEffect(asyncEffect(async () => { // eslint-disable-line react-hooks/exhaustive-deps
        setWorking(true)
        if (tokens?.size) {
            const remote = await Api.validateTokens(Array.from(tokens?.keys()))
            const verified = new Map<string, string>()
            remote.forEach((t) => verified.set(t, tokens.get(t)!))
            setTokens(verified)
        }
        setWorking(false)
    }), []);

    
    const removeToken = (token: string) => {
        const nt = new Map<string, string>();
        Array.from(tokens.keys()).forEach((k) => {
            if (k !== token) {
                nt.set(k, tokens.get(k) as any)
            }
        });
        setTokens(nt)
    };

    return (
        <>
        <h4>{t('Tokens saved in this browser')}</h4>
        <Table bordered striped size="sm" >
            <thead>
                <tr>
                    <th>{t('Account')}</th>
                    <th>{t('Token')}</th>
                    <th>{t('Action')}</th>
                </tr>
            </thead>
            <tbody>
                {working && <tr><td colSpan={3}>{t('Loading...')}</td></tr>}
                {tokens && Array.from(tokens.keys()).map((token) => (
                    <tr key={token}>
                        <td>{tokens.get(token)}</td>
                        <td>{token}</td>
                        <td>
                            <ConfirmDeleteButton onConfirm={() => removeToken(token)}  />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
        </>
    )
}


function LocalHistoryTable() {

    const [certs] = useLocalState<CertificateData[]>("my-certs", [])
    const {t} = useTranslation('common')

    return (
        <>
        <h4>{t('History of certificates')}</h4>
        <Table bordered striped size="sm" >
            <thead>
                <tr>
                    <th>{t('No.')}</th>
                    <th>{t('Account')}</th>
                    <th>{t('File')}</th>
                    <th>{t('Description')}</th>
                </tr>
            </thead>
            <tbody>
                {certs && certs.map((data: CertificateData) => (
                    <tr key={data.id}>
                        <td>
                            <a href={data.certUrl}>{data.id}</a>
                        </td>
                        <td>{data.accountName}</td>
                        <td>{data.fileName}</td>
                        <td>{data.description}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
        </>
    )
}


export default function LocalDashboard() {
    const {t} = useTranslation('common')
    const [auth] = useAuth()
    const history = useHistory()
    const [view, setView] = useState('tokens')
    return (
        <MainLayout>
            <Card>
                <Card.Body>
                    <div style={{padding:"10px"}}>
                        <Button onClick={() => setView('history')} variant="link" size="sm" disabled={view === 'history'}>
                            <FontAwesomeIcon icon="history" />{' '}
                            {t('History')}
                        </Button>
                        <Button onClick={() => setView('tokens')} variant="link" size="sm" disabled={view === 'tokens'}>
                            <FontAwesomeIcon icon="key" />{' '}
                            {t('Tokens')}
                        </Button>
                        <Button onClick={() => auth ? history.push('/dashboard') : history.push('/login')}  variant="link" size="sm">
                            <FontAwesomeIcon icon="user" />{' '}
                            {t('Account')}
                        </Button>
                    </div>
                </Card.Body>
                <Card.Body>
                    {view === 'tokens' && <LocalTokensTable />}
                    {view === 'history' && <LocalHistoryTable />}
                </Card.Body>
            </Card>
        </MainLayout>
    )
}