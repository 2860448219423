import QRCode from 'qrcode.react'
import { Component } from 'react'
import Settings from '../backend/settings'

import { v4 as uuidv4 } from 'uuid';
import { AuthInfo } from '../backend/api';

function _next_code() : string {
    return uuidv4() + '\n' + uuidv4() + '\n' + uuidv4() + '\n' + uuidv4()
}

type QRCodeViewState = {code: string | null, ws : WebSocket | null, countdown : number};
type QRCodeViewProps = {onMessage : (data: AuthInfo) => void}

class QRCodeView extends Component<QRCodeViewProps, QRCodeViewState> {
    
    private timeout = 250;
    private refresh = 10;
    private connectInterval? : ReturnType<typeof setTimeout>;
    private refreshInterval? : ReturnType<typeof setTimeout>;
    private isReady = false;

    constructor(props: QRCodeViewProps) {
        super(props);
        this.state = {
            ws: null,
            code: null,
            countdown: 0
        };
    }

    componentDidMount() {
        this.isReady = true;
        this.connect();
    }

    componentWillUnmount() {
        this.isReady = false;
        if (this.connectInterval) {
            clearTimeout(this.connectInterval);
        }
        if (this.refreshInterval) {
            clearTimeout(this.refreshInterval);
        }
        if (this.state.ws && this.state.ws.readyState !== WebSocket.CLOSED) {
            this.state.ws.close();
        }
    }

    sendCode = () => {
        if (this.isReady) {
            if (this.state.countdown === 0) {
                let code = _next_code();
                try {
                    if (this.state.ws) {
                        this.state.ws.send(code);
                        this.setState({code: code, countdown: this.refresh});
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
            else {
                this.setState({countdown: this.state.countdown - 1});
            }
            this.refreshInterval = setTimeout(this.sendCode, 1000);
        }
    };

    connect = () => {
        var ws = new WebSocket(Settings.authUrl);
        let that = this; // cache the this

        // websocket onopen event listener
        ws.onopen = () => {
            console.log("connected to auth server");
            if (that.refreshInterval) {
                clearTimeout(that.refreshInterval);
            }
            that.timeout = 250; // reset timer to 250 on open of websocket connection 
            if (that.connectInterval) {
                clearTimeout(that.connectInterval); // clear Interval on on open of websocket connection
            }
            that.setState({ ws: ws, countdown: 0 }, () => that.sendCode());
        };

        // websocket onclose event listener
        ws.onclose = e => {
            if (that.isReady) {
                console.log(
                    `Socket is closed. Reconnect will be attempted in ${Math.min(
                        10000 / 1000,
                        (that.timeout + that.timeout) / 1000
                    )} seconds.`,
                    e.reason
                );

                if (that.refreshInterval) {
                    clearTimeout(that.refreshInterval);
                }
    
                that.timeout = that.timeout + that.timeout; //increment retry interval
                that.connectInterval = setTimeout(that.check, Math.min(10000, that.timeout)); //call check function after timeout
            }
        };

        // websocket onerror event listener
        ws.onerror = (err : any) => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );

            ws.close();
        };

        ws.onmessage = (message : MessageEvent<any>) => {
            const info = JSON.parse(message.data) as AuthInfo
            that.props.onMessage(info);
        }
    };

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
    check = () => {
        const { ws } = this.state;
        if (!ws || ws.readyState === WebSocket.CLOSED) {
            this.connect(); //check if websocket instance is closed, if so call `connect` function.
        }
    };

    render() {
        if (this.state.code) {
            return (<div style={{textAlign:"center"}}>
                <QRCode value={this.state.code} size={256} />
                <div style={{textAlign:"center", marginTop:"30px"}} className="text-muted small">{this.state.countdown}s</div>
            </div>)
        }
        else {
            return <span></span>
        }
    }

}

export default QRCodeView;

