import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../assets/images/branding-logo-h.svg';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../backend/api';

interface BrandingBarProps {
    small? : boolean,
    getit? : boolean
}

export default function MainNavigationBar(props: BrandingBarProps) {
    const history = useHistory()
    const {t} = useTranslation('common')
    const [auth] = useAuth()
    const homeTarget = {
        pathname: "/",
        key: Math.round(Date.now() / 10) as any,
        state: {
            seed: Date.now(),
        }
    }
    return (
        <>
            <div className="navbar">
                <div style={{display:"flex", alignItems: "center", justifyItems: "center", justifyContent: 'center'}}>
                    <Link to={homeTarget}>
                        <img src={logo}  className="logo" alt="Logo" />
                    </Link>
                </div>
                <div style={{display:"flex", alignItems: "center", justifyItems: "center", justifyContent: 'center'}}>
                        <Button variant="link" className="btn-brand" onClick={() => history.push('/verify')}>
                            <FontAwesomeIcon icon="check-double" /><br />
                            {t('Verify')}
                        </Button>
                        <Button variant="link" className="btn-brand d-none d-md-block" onClick={() => history.push('/new')}>
                            <FontAwesomeIcon icon="stamp" /><br />
                            {t('Create')}
                        </Button>
                        <Button variant="link" className="btn-brand d-none d-md-block" onClick={() => history.push('/local')}>
                            <FontAwesomeIcon icon="book" /><br />
                            {t('Manage')}
                        </Button>
                        {/*
                        <Button variant="link" className="btn-brand">
                            <FontAwesomeIcon icon="shopping-cart" /><br />
                            {t('Buy')}
                        </Button>
                        */}
                        {props.getit !== false &&
                            <Button variant="link" className="btn-brand" onClick={() => history.push('/getit')}>
                                <FontAwesomeIcon icon="check" /><br />
                                {t('Try It')}
                            </Button>
                        }
                </div>
            </div>
        </>
    )
}