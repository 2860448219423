import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Api from "../backend/api";
import MainLayout from "../components/MainLayout";
import { useLocalState } from "../utils/hooks";

const queryString = require('query-string');

export default function ImportTokensPage(props: any) {
    const {t} = useTranslation('common')   
    const history = useHistory()
    const location = useLocation()
    const [tokens, setTokens] = useLocalState('my-tokens', new Map<string, any>())
    const [shared, setShared] = useState([])
    const data = queryString.parse(location.search) 
 
    const doImportTokens = async () => {
        console.log(data)
        if (data.type && data.type === 'client' && data.tokens) {
            const lst = data.tokens.split('-')
            setShared(lst)
            const remoteTokens = []
            for(const tokenId of lst) {
                try {
                    const token = await Api.getToken(tokenId)
                    if (token.type === 2) {
                        remoteTokens.push(token)
                    }
                }
                catch (error) {
                    console.log(error)
                }
            }
            console.log(remoteTokens)
            if (remoteTokens.length > 0) {
                for(const token of remoteTokens) {
                    tokens.set(token.id, token.account.name)
                }
                console.log("UPDATED: ", tokens)
                setTokens(new Map(tokens.entries()))
                history.replace('/new')
            }
        }
    };

    return <MainLayout>
        <div className="centered-wrapper">
            <div style={{ maxWidth: '100%', width: '800px' }}>
                <Card>
                    <Card.Body>
                        <Card.Title>{t('Import Tokens')}</Card.Title>
                        {shared &&
                            <Form>
                                <Form.Group controlId="shared-tokens">
                                    <Form.Label>{t('You have received the following tokens')}:</Form.Label>
                                    <Form.Control as="textarea" rows={3} value={data?.tokens?.replaceAll('-', ', ')} readOnly />
                                </Form.Group>
                            </Form>
                        }
                        <Card.Text>{t(`Do you want to import them into this browser?`)}</Card.Text>
                        <Card.Text>
                            <Button variant='primary' onClick={doImportTokens}>
                                <FontAwesomeIcon icon="download" />{' '}{t('Import')}
                            </Button>
                            {' '}
                            <Button variant="secondary" onClick={() => history.replace('/')} >{t('Cancel')}</Button>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </MainLayout>

}

