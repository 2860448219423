import { useState } from "react"
import { useQuery } from "@apollo/client"
import { Button, Card, Pagination, Table } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useAuth } from "../backend/api"
import { QUERY_CERTIFICATES } from "../backend/gql/certificates"
import { QUERY_TOKENS } from "../backend/gql/tokens"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Settings from "../backend/settings"
import MainLayout from "../components/MainLayout"

type DashboardProps = {}

function hashFormat(hash : string) {   
    if (hash) {
        return <pre className="small">
            {hash.match(/.{1,11}/g)?.map((chunk) => <div key={chunk}>{chunk}</div>)}
        </pre>
    }
    return <span></span>
}

function CertificatesTableData({loading, error, data}: any) {
    const { t } = useTranslation("common")
    const rows = data ? data.certificates.edges : []
    return (
        <>
            <Table striped hover responsive size="sm">
                <thead>
                    <tr>
                        <th>{t('#')}</th>
                        <th>{t('Token')}</th>
                        <th>{t('File Name')}</th>
                        <th>{t('File Hash')}</th>
                        <th>{t('Issued')}</th>
                        <th>{t('Revoked')}</th>
                        <th>{t('Notarization')}</th>
                        <th>{t('Description')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((edge : any, index: number) => {
                        const cert = edge.node
                        return (
                            <tr key={cert.id}>
                                <td>{cert.id}</td>
                                <td>{cert.token}</td>
                                <td><div style={{maxWidth:"170px"}}>{cert.fileName}</div></td>
                                <td>{hashFormat(cert.fileHash)}</td>
                                <td>{cert.issued}</td>
                                <td>
                                    {cert.revoked}
                                    <br />
                                    {cert.revocationCause}
                                </td>
                                <td>
                                    {hashFormat(cert.notarization?.block?.transaction)}
                                </td>
                                <td><div style={{maxWidth:"200px"}}>{cert.description}</div></td>
                                <td className="text-right">
                                    <Button variant="link" size="sm" onClick={() => {
                                        window.location.replace(Settings.apiUrl + "/certificates/" + cert.id + ".pdf")
                                    }}>
                                        <FontAwesomeIcon icon="file-pdf"  />
                                    </Button>
                                    {/*
                                    <Button variant="link" size="sm" onClick={() => {}}>
                                        <FontAwesomeIcon icon="ban" color="#ee0000" />
                                    </Button>
                                    */}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            {loading && <div>{t('Loading...')}</div>}
            {error && <div>{t('Error...')}</div>}
        </>
    )    
}

function CertificatesTable() {
    const [endOffset, setEndOffset] = useState<string | null>(null)
    const { loading, error, data } = useQuery(QUERY_CERTIFICATES, { variables: { endOffset }})
    const { t } = useTranslation("common")
    return (
        <div style={{fontSize:'0.8em'}}>
            <h3>{t('Certificates')}</h3>
            <CertificatesTableData loading={loading} error={error} data={data} />
            <PaginationBuilder offset={endOffset} setOffset={setEndOffset} data={data?.certificates} />
        </div>
    )
}

function tokenTypeDesc(id: number, t: any) {
    switch(id) {
        case 1: return t('Authentication')
        case 2: return t('Certification')
        case 3: return t('Transfer')
        default: return t('Error')
    }
}


function PaginationBuilder({offset, setOffset, data} : any) {
    const {t} = useTranslation('common')
    const [prev, setPrev] = useState([null] as Array<any>)
    if (!data) {
        return <div></div>
    }

    return (
        <Pagination>
            <Pagination.Item disabled={!data.pageInfo.hasPreviousPage} onClick={() => {
                if (prev.length > 1) {
                    setOffset(prev.pop())
                    setPrev([...prev])
                }
            }}>
                {t('Prev')}
            </Pagination.Item>
            {prev && <Pagination.Item active={true}>{prev.length}</Pagination.Item>}
            <Pagination.Item disabled={!data.pageInfo.hasNextPage} onClick={() => {
                setPrev([...prev, offset])               
                setOffset(data.pageInfo.endCursor)                
            }}>
                {t('Next')}
            </Pagination.Item>
        </Pagination>
    )
}

function TokensTableData({loading, error, data}: any) {
    const { t } = useTranslation("common")
    const rows = data ? data.tokens.edges : []
    return (
        <>
            <Table striped hover responsive size="sm">
                <thead>
                    <tr>
                        <th>{t('Grants')}</th>
                        <th>{t('Token')}</th>
                        <th>{t('Issued')}</th>
                        <th>{t('Description')}</th>
                        {/*<th></th>*/}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((edge : any, index: number) => {
                        const item = edge.node;
                        return(
                            <tr key={item.id}>
                                <td>{tokenTypeDesc(item.type, t)}</td>
                                <td>{item.id}</td>
                                <td>{item.issued}</td>
                                <td>{item.description}</td>
                                {/*}
                                <td className="text-right">
                                    <Button variant="link" size="sm" onClick={() => {}}>
                                        <FontAwesomeIcon icon="trash" color="#ee0000" />
                                    </Button>
                                </td>
                                */}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            {loading && <div>{t('Loading...')}</div>}
            {error && <div>{t('Error...')}</div>}
        </>
    )    
}


function TokensTable() {
    const [endOffset, setEndOffset] = useState<string | null>(null)
    const { loading, error, data } = useQuery(QUERY_TOKENS, { variables: { endOffset }})
    const { t } = useTranslation("common")
    return (
        <div style={{fontSize:'0.8em'}}>
            <h3>{t('Avaiable Tokens')}</h3>
            <TokensTableData loading={loading} error={error} data={data} />
            <PaginationBuilder offset={endOffset} setOffset={setEndOffset} data={data?.tokens} />
        </div>
    )
}

export default function Dashboard(props : DashboardProps) {
    const [auth] = useAuth()
    const { t } = useTranslation("common")
    const [panel, setPanel] = useState('certificates')
    return <MainLayout>
        <Card style={{margin: "20px"}}>
            <Card.Header>
                {auth?.accountName} :: {t('Admin Dashboard')}
            </Card.Header>
            <Card.Body>               
                {panel === 'certificates' && 
                <>
                    <Button className="float-right"  variant="link" size="sm" onClick={() => {
                        setPanel('tokens')
                    }}>{t('Tokens')}</Button>
                    <CertificatesTable />
                </>}
                {panel === 'tokens' && 
                <>
                    <Button className="float-right" variant="link" size="sm" onClick={() => {
                        setPanel('certificates')
                    }}>{t('Assets (Certificates)')}</Button>
                    <TokensTable />
                </>}
            </Card.Body>
        </Card>        
    </MainLayout>
}