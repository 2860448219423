import Card from 'react-bootstrap/Card'
import { useTranslation } from 'react-i18next'
import QRCodeView from '../components/QRCodeView'
import { Container, Row, Col, Badge } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MainLayout from '../components/MainLayout'
import { useContext } from 'react'
import { BackendAuthContext } from '../App'


function step_2_text(t: any) {
    let tr = t('Go to three dots menu $icon and select Web Access $icon').split("$icon");
    return <span>
        {tr[0]}{' '}
        <Badge variant="secondary"><FontAwesomeIcon icon="ellipsis-v" /></Badge>        
        {tr[1]}{' '}
        <Badge variant="secondary"><FontAwesomeIcon icon="qrcode" /></Badge>
    </span>
}

export default function LoginPage(props: {}) {
    const { t } = useTranslation('common')
    const history = useHistory()
    const {setAuth} = useContext(BackendAuthContext)
    return (<MainLayout>
        <div className="verify-form-wrapper">
            <div>
                <h3 className="verify-form-wrapper-title" >{t('Account Management')}</h3>
                <Card>
                    <Card.Body>
                        <Container fluid>
                            <Row>
                                <Col xs={7}>
                                    <h5>{t('To use Certihash on your computer')}:</h5>
                                    <div style={{padding:"10px"}} >
                                        <ol>
                                            <li>{t('Open Certihash in your phone')}</li>
                                            <li>{step_2_text(t)}</li>
                                            <li>{t('Point your phone to the screen to capture the code')}</li>
                                        </ol>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{padding: '20px'}}>
                                        <QRCodeView onMessage={(authInfo) => {
                                            console.log(authInfo);
                                            if (authInfo.state === 'signed') {      
                                                setAuth(authInfo);                                          
                                                history.replace('/dashboard');
                                            }
                                        }} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                    <Card.Footer>
                        {t('Scan the QR Code with Certihash Application')}
                    </Card.Footer>
                </Card>
            </div>
        </div>
    </MainLayout>)
}

