import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import Settings from './settings';

export function createApolloClient(authToken: string) {
    return new ApolloClient({
        link: new HttpLink({
            uri: Settings.graphqlUrl,
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }),
        cache: new InMemoryCache(),
    });
};

