import React from "react";
import {
    Switch,
    Route
} from "react-router-dom";
import Backend from "./components/Backend";
import CertificatePage from "./pages/certificate";
import CreateAssetPage from "./pages/create-asset";
import Dashboard from "./pages/dashboard";
import GetItPage from "./pages/getit";
import ImportTokensPage from "./pages/import-tokens";
import LocalDashboard from "./pages/local-dashboard";
import LoginPage from "./pages/login";
import VerifyCertPage from "./pages/verify-cert";

export default function AppRouter(props: {}) {
    return (
        <Switch>
            <Route exact path="/" render={(props) => <VerifyCertPage key={new Date() as any} />} />
            <Route exact path="/verify">
                <VerifyCertPage key={new Date() as any}/>
            </Route>
            <Route path="/login">
                <LoginPage />
            </Route>
            <Route path="/certificate/:id">
                <CertificatePage/>
            </Route>
            <Route path="/new">
                <CreateAssetPage/>
            </Route>
            <Route path="/import">
                <ImportTokensPage />
            </Route>
            <Route path="/local">
                <LocalDashboard />
            </Route>
            <Route path="/dashboard">
                <Backend>
                    <Dashboard/>
                </Backend>                
            </Route>
            <Route path="/getit">
                <GetItPage/>
            </Route>
        </Switch>
    )
}
