import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faEnvelope, faPhone, faClock, faEllipsisV, faQrcode, faDownload, 
  faFilePdf, faTrash, faBan, faSearch, faKey, faStamp, faShoppingCart, 
  faBook, faCheckDouble, faTimes, faUser, faHistory, faCheck
} from '@fortawesome/free-solid-svg-icons'
import AppRouter from './routes';

import React from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { useAuth } from './backend/api';


library.add(faEnvelope, faPhone, faClock, faEllipsisV, faQrcode, faDownload, 
  faFilePdf, faTrash, faBan, faSearch, faKey, faStamp, faShoppingCart, faBook,
  faCheckDouble, faTimes, faUser, faHistory, faCheck)

export const BackendAuthContext = React.createContext<any>(null);

function App() {
  const [auth, setAuth] = useAuth()
  const context = {auth, setAuth}
  return (
    <BackendAuthContext.Provider value={context}>
      <Router>
        <AppRouter />
      </Router>
    </BackendAuthContext.Provider>    
  );
}

export default App;
