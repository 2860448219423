import { gql } from "@apollo/client";

export const QUERY_TOKENS = gql`
query QUERY_TOKENS($endOffset: String) {
    tokens: ch_token_connection(where: {_and: {used_: {_is_null: true}, revoked_: {_is_null: true}}}, order_by: {issued_: desc, id_: asc}, 
        first: 10, after: $endOffset) {
        edges {
            node {
                id: id_
                issued: issued_
                description: note_
                type: type_
            }
            cursor
        }
        pageInfo {
            endCursor
            hasPreviousPage
            hasNextPage
        }
    }
}
`;